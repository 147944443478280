:root {
    --color: #212121;
    --color-drop-light: #ECEFF1;
    --color-drop-dark: #607D8B;
    --color-dropping-light: #E8F5E9;
    --color-dropping-dark: #4CAF50;
    --color-preview-item: #CFD8DC;
    --color-disabled: #F5F5F5;
    --color-card: #FFFFFF;
    --color-background: #90A4AE;
    --color-loader: #90A4AE;
    --color-button-1: #607D8B;
    --color-button-2: #FFFFFF;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}




.file-drag {
    --gap: 10px;
    --speed: 1s;
    --stroke: 1px;
    margin: 1em 0;
    cursor: default;
    background-color: var(--color-drop-light);
    color: var(--color-drop-dark);
    background-image: linear-gradient(to top, currentColor var(--gap), var(--color-card) var(--gap)),
    linear-gradient(to right, currentColor var(--gap), var(--color-card) var(--gap)),
    linear-gradient(to bottom, currentColor var(--gap), var(--color-card) var(--gap)),
    linear-gradient(to left, currentColor var(--gap), var(--color-card) var(--gap));
    background-size: var(--stroke) calc(2 * var(--gap)),
    calc(2 * var(--gap)) var(--stroke),
    var(--stroke) calc(2 * var(--gap)),
    calc(2 * var(--gap)) var(--stroke);
    background-repeat: repeat-y,
    repeat-x,
    repeat-y,
    repeat-x;
    animation: background-position var(--speed) linear infinite;
    will-change: background-position;
}

.file-drag.hover {
    background-color: var(--color-dropping-light);
    color: var(--color-dropping-dark);
}

@keyframes background-position {
    from {
        background-position: 0% 0px,
        0px 100%,
        100% 0px,
        0px 0%;
    }
    to {
        background-position: 0% calc(4 * var(--gap)),
        calc(4 * var(--gap)) 100%,
        100% calc(-4 * var(--gap)),
        calc(-4 * var(--gap)) 0%;
    }
}

.image-preview {
    display: inline-block;
    max-width: 60px;
    max-height: 60px;
    margin: 2px;
    padding: 5px;
}

.preview {
    display: inline-block;
    padding: 5px;
    margin: 2px;
    max-width: 200px;
    max-height: 100px;
    overflow: auto;
    word-break: break-all;
    white-space: pre-wrap;
}

.preview-item {
    display: flex;
    align-items: center;
    align-content: center;
    margin: 5px 0;
    background: var(--color-preview-item);
}

.preview-item.disabled {
    background: var(--color-disabled);
    pointer-events: none;
}

.previews {
}

.input {
    position: absolute;
    top: 0;
    left: -10em;
    height: 0;
    width: 0;
    opacity: 0;
}

.loader {
    height: 60px;
    width: 60px;
    position: relative;
}

.loader-item {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 10px;
    width: 10px;
    margin: -5px;
    border-radius: 100%;
    background: var(--color-loader);
    animation: load-item .8s ease-in-out infinite;
}

@keyframes load-item {
    from {
        transform: rotate(0deg) translateY(20px);
    }
    to {
        transform: rotate(360deg) translateY(20px);
    }
}

.button {
    font-size: .8em;
    margin-right: .5em;
    padding: .35em .5em;
    border: 1px solid;
    cursor: pointer;
    background: transparent;
    color: var(--color-button-1);
    white-space: nowrap;
    font-weight: lighter;
    text-transform: uppercase;
}

.button:hover, .button:focus {
    background: var(--color-button-1);
    border-color: var(--color-button-1);
    color: var(--color-button-2);
}

.button:active {
    background: var(--color-button-1), var(--color-button-2);
    border-color: var(--color-button-1), var(--color-button-2);
    color: var(--color-button-2);
}

.button:focus {
    outline: 0;
}

.input-wrapper {
    position: relative;
    width: 100%;
    overflow: hidden;
    color: var(--color);
}

.file-name {
    flex: 0 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.file-ext {
    flex: 1;
}

.input-cover {
    padding: 1em;
    display: flex;
    align-items: center;
    align-content: center;
    border-radius: 2px;
}

.help-text {
    padding: 1em;
    display: inline-block;
    color: var(--color);
}

.spacer {
    flex: 1;
}


/*!* puck*!*/

/*.Puck{*/
/*    direction: ltr !important;*/
/*}*/

#preview-frame {
    padding: 36px 0;
}

._Puck-layout_mowzv_29 {
    height: 100% !important;
}

._Puck-header_mowzv_105 {
    max-width: 100%;
}

._MenuBar-history_8pf8c_45 {
    direction: ltr;
}


/* width */
::-webkit-scrollbar {
    width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px grey;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: grey;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #6e6a6a;
}

.Flex {
    display: flex;
    gap: 24px;
    min-height: 0;
    min-width: 0;
    flex-wrap: wrap;
}

.Flex-item {
    flex: 1;
}


.ButtonGroup-actions {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.ButtonGroup-center .ButtonGroup-actions {
    justify-content: center;
}


.ButtonGroup-right .ButtonGroup-actions {
    justify-content: right;
}


.Heading {
    display: block;
    color: var(--puck-color-black);
    font-weight: 700;
    margin: 0;
}

.Heading b {
    font-weight: 700;
}

.Heading--xxxxl {
    font-size: var(--puck-font-size-xxxxl);
    letter-spacing: 0.08ch;
    font-weight: 800;
}

.Heading--xxxl {
    font-size: 34px;
}

.Heading--xxl {
    font-size: 30px;
}

.Heading--xl {
    font-size: 28px;
}

.Heading--l {
    font-size: 24px;
}

.Heading--m {
    font-size: 22px;
}

.Heading--s {
    font-size: 20px;
}

.Heading--xs {
    font-size: var(--puck-font-size-xs);
}

.main-menu ul {
    list-style: none;
    position: relative;
    float: left;
    margin: 0;
    padding: 0
}

.main-menu ul a {
    /*display:block;*/
    color: #333;
    text-decoration: none;
    font-size: 17px;
    line-height: 32px;
    padding: 0 15px;
    font-family: "IBM Plex Sans Arabic";
}

.main-menu ul li {
    position: relative;
    float: left;
    margin: 0;
    padding: 0
}

.main-menu ul li.active {
    background: #2f64d3;
    border-radius: 25px;
}

.main-menu ul li.active a {
    color: #fff;
}

.main-menu ul li:hover {
    background: #DBE8FC;
}

.main-menu ul li:hover a {
    color: #000000;
}

.main-menu ul ul {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background: #fff;
    padding: 0
}

.main-menu ul ul li {
    float: none;
    width: 200px;
    padding: 5px;
}

/*.main-menu ul ul a{*/
/*    line-height:120%;*/
/*    padding:10px 15px*/
/*}*/

.main-menu ul ul ul {
    top: 0;
    left: 100%
}

.main-menu ul li:hover > ul {
    display: block
}

[dir="rtl"] .main-menu ul ul ul {
    left: unset;
    right: 100%;
}

@media screen and (max-width: 768px) {
    [dir="rtl"] .main-menu ul ul ul {
        left: unset;
        right: 0;
        top: 40px;
    }

    .main-menu ul ul li {
        width: 286px !important;
    }
}

[dir="rtl"].main-menu ul ul {
    left: unset;
    right: 0;
}

[dir="rtl"] .main-menu ul li {
    float: right;
}

[dir="rtl"] .main-menu ul {
    float: right;
}

[dir="rtl"] .main-menu ul ul {
    left: unset;
    right: 0;
}


[dir="rtl"] .select-arrow .grid.place-items-center {
    left: 10px !important;
}

.adahy-style {
    background-image: url(https://alkhaircharity.org/img/adahi/2024/bg_cow.webp);
    background-repeat: no-repeat;
    background-size: 20%;

}

[dir="ltr"] .adahy-style {

    background-position-x: 855px
}

@media screen and (max-width: 768px) {
    .adahy-style {
        background-image: url(https://alkhaircharity.org/img/adahi/2024/bg_cow.webp);
        background-repeat: no-repeat;
        background-size: 50%;
        background-position-y: 20px;

    }


    [dir="ltr"] .adahy-style {
        background-image: url(https://alkhaircharity.org/img/adahi/2024/bg_cow.webp);
        background-repeat: no-repeat;
        background-size: 45%;
        background-position-y: 70px;
        background-position-x: 200px !important;
    }
}


.story-image + .nav-container {
    margin: 0 !important;
    padding: 0 !important;
}

.items-start {
    align-items: flex-start !important;
}

.mk-rtl {
    direction: rtl !important;
}

.pic {
    position: relative;
    width: 488px;
    min-width: 488px;
    height: 429px;
    overflow: hidden;
}


@media screen and (max-width: 768px) {
    .pic {
        position: relative;
        width: 270px;
        min-width: unset !important;
        height: 429px;
        overflow: hidden;
    }

    .help-card-content .pic span.img {

        width: 200px !important;
        height: 200px !important;

    }

    .help-card-content .pic::after {
        bottom: 120px !important;
    }

    .image-dubol {
        display: none !important;
    }
}

.help-card-content .pic::after {
    position: absolute;
    content: "";
    right: 0;
    bottom: 0;
    width: 210px;
    height: 210px;
    background-color: #EBD305;
    border-radius: 50%;
    z-index: 1;
}

.help-card-content .pic span.img {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 400px;
    height: 400px;
    border-radius: 50%;
    overflow: hidden;
    z-index: 3;
}

.help-card-content .pic span img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}


.contribute-good {
    padding: 66px 0;
    background-color: #fff;
}

section {
    display: block;
    unicode-bidi: isolate;
}

.contribute-good .help-card-content .content .title {
    font-size: 18px;
    color: #EBD305;
    margin-bottom: 16px;
}

.help-card-content .content .title {
    font-size: 18px;
    color: #EBD305;
    margin-bottom: 16px;
}

.contribute-good .help-card-content .content .sub-title {
    font-size: 35px;
    color: #001D35;
    margin-bottom: 24px;
}

.help-card-content .content .sub-title {
    font-size: 35px;
    color: #001D35;
    margin-bottom: 24px;
}


.contribute-good .help-card-content .content .info {
    font-size: 15px;
    color: #001D35;
    line-height: 1.8;
}

.help-card-content .content .info {
    font-size: 15px;
    color: #001D35;
    line-height: 1.6;
}

.contribute-good .help-card-content .pic span.info {
    position: absolute;
    content: "";
    right: 0;
    bottom: 0;
    width: 310px;
    height: 310px;
    background-color: rgba(0, 29, 53, 0.7);
    border-radius: 50%;

    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 50px;
    font-size: 24px;
    text-align: center;
    z-index: 4;
}

.contribute-good .help-card-content .pic span.info a {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background-color: #fff;
    color: #001D35;
    width: 80%;
    font-size: 14px;
    padding: .5rem 1rem;
    border-radius: 12px;
    margin-top: 16px;
    transition: all .3s ease;
}

.contribute-good .help-card-content .pic span.info .text-yellow {
    color: #EBD305;
}

.join-us-container .join-us-card {
    position: relative;
    background-color: #1A356E;
    color: #fff;
    overflow: hidden;
}

.join-us-container .join-us-card .content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.join-us-container .join-us-card .content .icon {
    margin-bottom: 16px;
}

.join-us-container .join-us-card .content .icon, .join-us-container .join-us-card .content .title {
    padding-inline-start: 40px;
}

.join-us-container .join-us-card:last-child .content .title {
    position: relative;
    color: #fff;
    padding: 5%;
    font-size: 24px;
    text-align: center;
    line-height: 2;
    margin-bottom: 0;
}

.join-us-container .join-us-card .content .title {
    color: #F5DE1D;
    font-size: 24px;
    margin-bottom: 26px;
}

.join-us-container .join-us-card .content .icon, .join-us-container .join-us-card .content .title {
    padding-inline-start: 40px;
}

.join-us-container .join-us-card .content .btn-more {
    display: inline-block;
    background-color: #fff;
    padding: 10px;
    border-end-end-radius: 50px;
    border-start-end-radius: 50px;
}

.join-us-container .join-us-card .content .btn-more .icon {
    width: 40px;
    height: 40px;
    line-height: 35px;
    display: block;
    border-radius: 50%;
    background-color: #EBD305;
    margin-inline-start: 86px;
    color: #001D35;
    text-align: center;
    margin-bottom: 0;
    font-size: 30px;
    padding: 0;
}

.join-us-container .join-us-card .content .icon {
    margin-bottom: 16px;
}

.join-us-container .join-us-card .content .icon, .join-us-container .join-us-card .content .title {
    padding-inline-start: 40px;
}

.btn-more {
    color: #1A356E;
    transition: all .5s ease;
}

.join-us-card img {
    transition: all .3s ease;
}

.join-us-card:hover img {
    transform: scale(1.5);
}

.fade-enter {
    opacity: 0;
}

.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms;
}

.fade-exit {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0;
    transition: opacity 300ms;
}

.aytam-list .section-header {
    margin-bottom: 47px;
}

.aytam-list .section-header .title {
    font-size: 18px;
    color: #EBD305;
    margin-bottom: 18px;
}

.aytam-list .section-header .sub-title {
    font-size: 35px;
    color: #001D35;
    margin-bottom: 0;
}

.aytam-list .filters {
    display: flex;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
    margin-bottom: 36px;
    position: relative;
    z-index: 9;
}

.form-check-input[type=checkbox] {
    border-radius: .25em;
}

.form-check-input {
    width: 1em;
    height: 1em;
    margin-top: .25em;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(0, 0, 0, .25);

    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    print-color-adjust: exact;
}

.form-check-label {
    font-weight: bold;
    color: #000000;
    font-size: 13px;
    margin-inline-start: 1rem;
}

.country_select {
    width: 216px;
    height: 51px;
    /*box-shadow: 0px 13px 49px #2097D31F;*/
    border-radius: 15px;
    color: #000;
    font-weight: bolder;
    margin-right: 100px;
}

.form-select {
    display: block;
    padding: .375rem .75rem .375rem 2.25rem;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: left .75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: .375rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.aytam-list .filters .btn-submit {
    width: 236px;
    height: 50px;
    padding-top: 0;
    line-height: 40px;
    color: #fff;
    background: #1A356E;
    box-shadow: 0px 13px 74px #2097D336;
    border-radius: 15px;
}


.aytem-btn-more:hover {
    color: #fff !important;
    background-color: rgb(26, 53, 110) !important;
}

.aytm-btn {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    border: none;
    padding: 0 14px;
    min-width: 64px;
    height: 45px;
    line-height: 45px;
    border-radius: 10px;
    vertical-align: middle;
    text-align: center;
    text-overflow: ellipsis;
    font-size: 16px;
    overflow: hidden;
    outline: none;
    cursor: pointer;
    font-weight: 700;
    transition: box-shadow 0.2s;
}

.projects {
    padding: 64px 0;
    background-color: #fff;
}

.projects .section-header {
    max-width: 500px;
    margin: auto;
    text-align: center;
    margin-bottom: 26px;
}

.projects .section-header .title {
    color: #EBD305;
    font-size: 18px;
    margin-bottom: 18px;
}

.projects .section-header .sub-title {
    color: #001D35;
    font-size: 35px;
    line-height: 1.8;
}

.projects .section-header hr {
    width: 52px;
    height: 4px;
    margin: auto;
    background: #F5DE1d;
    border-radius: 50px;
    border: none;
}

section.video {
    position: relative;
    background-color: #001d35;
    background-image: url("https://alkhaircharity.org/orphan_asset/img/header.png");
    background-size: cover;
    min-height: 477px;
    display: flex;
    align-items: center;
    justify-content: center;
}

section.video::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent linear-gradient(180deg, #1A356E 0%, #0D1B37 100%) 0% 0%;
    opacity: 0.53;
}

.video-card {
    position: relative;
    max-width: 402px;
    margin: auto;
    z-index: 1;
    text-align: center;
}

.video-card .title {
    color: #EBD305;
    font-size: 18px;
    margin-bottom: 18px;
}

.video-card .sub-title {
    color: #fff;
    font-size: 35px;
    line-height: 1.8;
}

.video-card .btn-video {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: #fff;
    color: #EBD305;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.fa-play:before {
    content: "\f04b";
}

.counters-section .counters-container {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    min-height: 200px;
    border-radius: 10px;
    overflow: hidden;
    background: #FFFFFF;
    box-shadow: 0px 10px 80px #2929291A;
    background-image: url("https://alkhaircharity.org/orphan_asset/img/pattern.png");
    background-size: cover;
}


#mainImagePreview {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    margin-right: 10px;
    filter: drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 2px);
    border: 2px solid rgba(255, 255, 255, 0.8);
}

.steps .step-primary + .step-primary:before, .steps .step-primary:after {
    --tw-bg-opacity: 1;
    background-color: oklch(0.55 0.22 262.89);
    --tw-text-opacity: 1;
    color: var(--fallback-pc, oklch(var(--pc) / var(--tw-text-opacity)));
}


input:focus-visible {
    outline: unset !important;
    border-bottom: 1px solid #d4e0e5 !important;
}

*, button, a {
    transition: all 0.2s;
}


.page > *:not(.second-navbar) {
    max-width: 80rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    position: relative;
}


.hide-scroll {
    scrollbar-width: none !important;
}

.story-content > div {
    bottom: -16px !important;
}

.react-multi-carousel-dot--active button {
    background: #af9d9d80 !important;
}


.gradient-border {
    /* Create a border with gradient */
    border: 3px solid transparent;
    /* Pseudo-element to create the gradient */
    background-image: linear-gradient(225deg, #8A3BEE 0%, #F20000 39%, #3B4BA7 91.67%), linear-gradient(225deg, #8A3BEE 0%, #F20000 39%, #3B4BA7 91.67%);
    background-origin: border-box;
    background-clip: content-box, border-box;
}


.indicator {
    --gap: 1rem;
    --item: 0;
    left: calc(var(--item, 1) * (4rem + var(--gap, 1)));
    transition: left 300ms ease;
}


.fixed-top {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000; /* Ensure it stays on top of other content */
}

.phone{
    display: none !important;
}

@media screen and (max-width: 768px) {
    .phone{
        display: unset !important;
    }

    .web{
            display: none !important;

    }
    .donation-bow-fixed{
        display: flex !important;
    }

}

.donation-bow-fixed {
    display: none;
    position: fixed;
    bottom: 0;
    z-index: 99999;

    gap: 10px;
    justify-content: space-between;
    align-items: center;
    height: 61px;
    background: #ffffff;
    color: #fff;
    padding: 0 0.8rem;
    width: 100%;
    border-radius: 15px 15px 0  0 ;
}

.donation-bow-fixed .form-group {
    display: flex;
    gap: 1rem;
}

.donation-bow-fixed .btn-submit {
    margin-top: -3.5rem;
}



.donation-bow-fixed .form-group {
    display: flex;
    gap: 1rem;
}

.donation-bow-fixed .form-group .btn {
    min-width: 45px;
    width: 45px;
    height: 45px;
    background: #1d4ed7;
    border-radius: 11px;
    padding: 0;
    color: #fff;
}



.donation-bow-fixed .form-group .form-control {
    background-color: #FFFFFF;
    border: 1px solid #001D3542;
    border-radius: 10px;
    text-align: center;
    box-shadow: none;
    font-size: 16px;
    font-family: "bold";
    color: #001D35;
}

.donation-bow-fixed .form-group .input-group-text {
    color: #001D35 !important;
    background: #DDE5F6;
    color: #fff;
    justify-content: center;
    padding: 0;
    border: none;
    border-radius: 10px;
    border-color: transparent;
    margin-bottom: 1px;
    margin-top: 1px;
    font-weight: bold;
}

.translate-middle {
    transform: translate(50%, -50%) !important;
}

.donation-bow-fixed .cart path{
    fill:white;
}

.calc-btn:hover .calc-nav{
    fill: white;
}


.modal-box [style="isolation: isolate; color-scheme: light;"]{
    background: rgba(255, 255, 255, 0);
}

[dir="rtl"] .modal-box [style="isolation: isolate; color-scheme: light;"] label{
    font-family: inherit;
    font-size: large;
    text-align: end;
}

[dir="rtl"] .modal-box [style="isolation: isolate; color-scheme: light;"] output{
    font-family: inherit;
    font-size: large;
    text-align: start;
}

[dir="rtl"] .modal-box [style="isolation: isolate; color-scheme: light;"] [role="presentation"]{
    display: flex;
    flex-direction: row-reverse;
}

.modal-box [style="isolation: isolate; color-scheme: light;"] label{
    font-family: inherit;
    font-size: large;
}

.modal-box [style="isolation: isolate; color-scheme: light;"] output{
    font-family: inherit;
    font-size: large;
}

[dir="ltr"] .arrow-breadcrumbs{
    transform: rotate(180deg);
}



.main-menu-phone ul {
    list-style: none;
    position: relative;
    float: left;
    margin: 0;
    padding: 0
}

.main-menu-phone ul a {
    /*display:block;*/
    color: #333;
    text-decoration: none;
    font-size: 17px;
    line-height: 32px;
    padding: 0 15px;
    font-family: "IBM Plex Sans Arabic";
}

.main-menu-phone ul li {
    position: relative;
    float: left;
    margin: 0;
    padding: 0
}

.main-menu-phone ul li.active {
    background: #2f64d3;
    border-radius: 25px;
}

.main-menu-phone ul li.active a {
    color: #fff;
}

.main-menu-phone ul li:hover {
    background: #DBE8FC;
}

.main-menu-phone ul li:hover a {
    color: #000000;
}

.main-menu-phone ul ul {
    /*display: none;*/
    position: absolute;
    top: 100%;
    left: 0;
    background: #fff;
    padding: 0
}

.main-menu-phone ul ul li {
    float: none;
    width: 200px;
    padding: 5px;
}

/*.main-menu-phone ul ul a{*/
/*    line-height:120%;*/
/*    padding:10px 15px*/
/*}*/

.main-menu-phone ul ul ul {
    top: 0;
    left: 100%
}

.main-menu-phone ul li:hover > ul {
    display: block
}

[dir="rtl"] .main-menu-phone ul ul ul {
    left: unset;
    right: 100%;
}

@media screen and (max-width: 768px) {
    [dir="rtl"] .main-menu-phone ul ul ul {
        left: unset;
        right: 0;
        top: 40px;
    }

    .main-menu-phone ul ul li {
        width: 286px !important;
    }
}

[dir="rtl"].main-menu-phone ul ul {
    left: unset;
    right: 0;
}

[dir="rtl"] .main-menu-phone ul li {
    float: right;
}

[dir="rtl"] .main-menu-phone ul {
    float: right;
}

[dir="rtl"] .main-menu-phone ul ul {
    left: unset;
    right: 0;
}





.badge-cart {
    height: 13px;
    padding-left: 2px;
    padding-right: 2px;
    border-radius: var(--rounded-badge, 1.9rem);
    border-width: 4px;
}

.badge-cart-notify {
    border-color: transparent;
    --tw-bg-opacity: 1;
    background-color: var(--fallback-su, oklch(0.56 0.26 258.31));
    --tw-text-opacity: 1;
    color: var(--fallback-suc, oklch(var(--suc) / var(--tw-text-opacity)));
}