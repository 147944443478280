:root {
    --primary-color: #0D6FFA;
    --accent-color: #49CE95;
    --danger-color: #EC3582;
    --fore-color: rgb(37, 99, 234);
    --main-cast-shadow: 0px 3px 12px rgba(0, 0, 0, 0.08), 0px 3px 6px rgba(0, 0, 0, 0.12);
}


.sc-bottom-bar {
        position: fixed;
        /*margin-right: -15px;*/
        padding: 18px 45px;
        justify-content: space-between;
        width: 100%;
        bottom: 0;
        right: 0;

        background-image: radial-gradient(circle at 30px 5px, transparent 29px, #FFFFFF 25px);
        filter: drop-shadow(0px -1px 8px rgba(0, 0, 0, 0.08)) drop-shadow(0px -2px 12px rgba(0, 0, 0, 0.12));
        transition: cubic-bezier(0.57, 0.23, 0.08, 0.96) .45s;
}

@media screen and (max-width: 768px) {
    .sc-bottom-bar{
        display: flex;
    }
}
.sc-nav-indicator {
    position: absolute;
    width: 40px;
    height: 40px;
    bottom: 28px;
    margin: auto;
    left: 0;
    background-color: #2f64d2;
    box-shadow: var(--main-cast-shadow);
    border-radius: 50%;
    transition: cubic-bezier(0.45, 0.73, 0, 0.59) .3s;
}

.sc-menu-item {
    color: var(--fore-color);
    transition: ease-in-out .3s;
    cursor: pointer;
}

.sc-menu-item svg{
    fill: black
}

.sc-current {
    position: relative;
    color: #ffffff;
    z-index: 3;
    transform: translate3d(0px, -22px, 0px);
}

.sc-current .cart{
    fill: white;


}.sc-current .home-icon{
    fill: none;
}